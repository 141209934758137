<template>
  <div class="survey-create-view tw-mb-10">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "SurveyCreateView",

  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Build Your Survey",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id,
    });
  },
};
</script>

<style lang="scss" scoped></style>
